type TConfig = {
  common: string[];
  feature: string;
};

export const gameBadgesListTranslationConfig: TConfig = {
  common: [],
  feature: 'Feature.GameBadges'
};

export default {
  gameBadgesListTranslationConfig
};
